// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail {
    height: 60vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.mail__block {
    margin-left: 50px;
    width: 500px;
}

.mail__title {
    margin-bottom: 25px;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.mail__subtitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

@media (max-width: 1024px) {
    .mail {
        flex-direction: column;
        text-align: center;
    }
    .mail__block {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .mail__img {
        width: 320px;
    }
    .mail__block {
        width: 420px;
    }
    .mail__title {
        font-size: 24px;
    }
    .mail__subtitle {
        font-size: 16px;
    }
}

@media (max-width: 425px) {
    .mail__img {
        width: 310px;
    }
    .mail__block {
        width: 320px;
    }
    .mail__title {
        font-size: 22px;
    }
    .mail__subtitle {
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/auth/mail/mail.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,mBAAmB;;IAEnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;IACA;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".mail {\n    height: 60vh;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.mail__block {\n    margin-left: 50px;\n    width: 500px;\n}\n\n.mail__title {\n    margin-bottom: 25px;\n\n    font-size: 32px;\n    font-style: normal;\n    font-weight: 700;\n}\n\n.mail__subtitle {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n}\n\n@media (max-width: 1024px) {\n    .mail {\n        flex-direction: column;\n        text-align: center;\n    }\n    .mail__block {\n        margin-left: 0;\n    }\n}\n\n@media (max-width: 768px) {\n    .mail__img {\n        width: 320px;\n    }\n    .mail__block {\n        width: 420px;\n    }\n    .mail__title {\n        font-size: 24px;\n    }\n    .mail__subtitle {\n        font-size: 16px;\n    }\n}\n\n@media (max-width: 425px) {\n    .mail__img {\n        width: 310px;\n    }\n    .mail__block {\n        width: 320px;\n    }\n    .mail__title {\n        font-size: 22px;\n    }\n    .mail__subtitle {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
