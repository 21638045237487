// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#feedback__section {
    position: relative;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
}

#feedback__section p {
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 53px;
}

.feedback__cards__container {
    position: relative;
    display: grid;
    grid-template-areas: "first first two";
    gap: 20px 20px;
}

.feedback__card:nth-child(1) {
    grid-area: first;
}

.feedback__card:nth-child(2) {
    grid-area: two;
}

@media (max-width:1024px) {
    #feedback__section p {
        font-size: 19px;
        line-height: 22.8px;
        margin-top: 32px;
    }

    #feedback__section p::after {
       content: " 💬";
    }

    .feedback__cards__container {
        grid-template-columns: 1fr;
        grid-template-areas: none;
    }

    .feedback__card:nth-child(1) {
        grid-area: initial;
    }

    .feedback__card:nth-child(2) {
        grid-area: initial;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/main/sections/feedback/feedbackSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;QACf,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;OACG,cAAc;IACjB;;IAEA;QACI,0BAA0B;QAC1B,yBAAyB;IAC7B;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["#feedback__section {\n    position: relative;\n    margin-top: 90px;\n    display: flex;\n    flex-direction: column;\n}\n\n#feedback__section p {\n    font-size: 48px;\n    font-weight: 700;\n    line-height: 57.6px;\n    letter-spacing: -0.02em;\n    text-align: left;\n    margin-bottom: 53px;\n}\n\n.feedback__cards__container {\n    position: relative;\n    display: grid;\n    grid-template-areas: \"first first two\";\n    gap: 20px 20px;\n}\n\n.feedback__card:nth-child(1) {\n    grid-area: first;\n}\n\n.feedback__card:nth-child(2) {\n    grid-area: two;\n}\n\n@media (max-width:1024px) {\n    #feedback__section p {\n        font-size: 19px;\n        line-height: 22.8px;\n        margin-top: 32px;\n    }\n\n    #feedback__section p::after {\n       content: \" 💬\";\n    }\n\n    .feedback__cards__container {\n        grid-template-columns: 1fr;\n        grid-template-areas: none;\n    }\n\n    .feedback__card:nth-child(1) {\n        grid-area: initial;\n    }\n\n    .feedback__card:nth-child(2) {\n        grid-area: initial;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
