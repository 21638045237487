import auth from "../api/auth"
import isMobileDevice from "./isMobileDevice";

function smoothScroll(element, targetPosition, duration = 500) {
    const start = element.scrollTop;
    const distance = targetPosition - start;
    const startTime = performance.now();

    function scrollStep(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        element.scrollTop = start + distance * easeInOutCubic(progress);

        if (progress < 1) {
            requestAnimationFrame(scrollStep);
        }
    }

    function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    requestAnimationFrame(scrollStep);
}

export default async function getUserTarif({ callback }) {
    const { getUserStatus } = auth

    try {
        const response = await getUserStatus()
        const { paymentPlan, status } = response.data

        if (status === "not_paid") {
            const sideblock = document.querySelector(".sideblock__content")
            const targetSection = document.getElementById("donate__block")

            if (sideblock && targetSection) {
                const targetPosition = targetSection.offsetTop - sideblock.offsetTop

                if(!isMobileDevice()) {
                    sideblock.scrollTo({
                        top: targetPosition,
                        behavior: "smooth",
                    })
                } else {
                    window.scrollTo({
                        top: targetPosition + 400,
                        behavior: "smooth",
                    })
                }
            }
            return
        }

        if (typeof callback === "function") {
            callback()
        }
    } catch (error) {
        console.error("Ошибка запроса:", error)
    }
}