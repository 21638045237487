import React, { useState, useContext, useEffect } from 'react'
import './sideblock.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { SideblockCard } from './sideblock'
import { Albums } from '../albums/albums'
import { Events } from '../events/events'
import shelters from '../../../../api/shelters'
import LocalStorage from '../../../../utils/LocalStorage'
import { PointContext } from '../../../../context/ChosenItem'
import event from '../../../../api/event'
import { Store } from 'react-notifications-component'
import axios from 'axios'
import isMobileDevice from '../../../../assets/isMobileDevice';

export const Place = () => {
    let [sheltersTotalCount, setSheltersTotalCount] = useState([])
    let [myEvents, setMyEvents] = useState([])

    const { getMyEvents } = event;
    // const { distanceItem } = useContext(distContext)
    const { save, get } = new LocalStorage
    const [coords, setCoords] = useState(get('COORD'))
    const { getShelters } = shelters
    const { subscribeToEvent } = event
    const { selectedItem, setSelectedItem } = useContext(PointContext)

    const [sheltersData, setSheltersData] = useState([])
    const [chosedShelter, setChosedShelter] = useState(null)
    const [isShelterLoading, setShelterLoading] = useState(true)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [isBeginning, setIsBeginning] = useState(true)
    const [isMiddle, setIsMiddle] = useState(false)
    const [isEnd, setIsEnd] = useState(false)
    // useEffect(() => {
    //     if (distanceItem) {
    //         setCoords({
    //             latitude: distanceItem[0],
    //             longitude: distanceItem[1]
    //         })
    //     }
    // }, [distanceItem])

    const handleEventSelect = (event) => {
        setSelectedEvent(event)
    }

    const handleButtonClick = async () => {
        const res = await subscribeToEvent({ id: selectedEvent.id, selected: [] })
     
        if (res.status === 201) {
           
            Store.addNotification({
                title: "Успех",
                message: 'Вы принимаете участие! Для подробностей перейдите в "Мои мероприятия"',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            })
        } else {
            Store.addNotification({
                title: "Ошибка",
                message: "При оформлении подписки произошла ошибка",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            })
        }

        const fetchData = async () => {
            try {

                // Получаем данные о событиях
                const selectedEvent = await getMyEvents();

                setMyEvents(selectedEvent.data)
            } catch (err) {
                console.error("Ошибка при получении данных:", err);
            }
        };

        fetchData(); // Вызов асинхронной функции для получения данных
    }


    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371 // km
        var dLat = toRad(lat2 - lat1)
        var dLon = toRad(lon2 - lon1)
        var lat1 = toRad(lat1)
        var lat2 = toRad(lat2)

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        var d = R * c
        //   console.log(isNaN(d), 'distanse')
        if (isNaN(d) || d === null) {
            return 0
        } else {
            return d
        }
    }

    function toRad(Value) {
        return Value * Math.PI / 180
    }

    const getSheltersFunc = async () => {
        try {
            setShelterLoading(true)
            const res = await getShelters()
            save('SHELTERS', res.data)

            let shelters = [...res.data]
            // console.log(coords, 'COORDS')

            let updatedShelters
            if (get('COORD')) {
                updatedShelters = shelters.map(item => ({
                    ...item,
                    distance: calcCrow(item.latitude, item.longitude, coords[0], coords[1])
                }))
            } else {
                updatedShelters = shelters.map(item => ({
                    ...item,
                    distance: 0.00
                }))
            }

            setSheltersData(updatedShelters.sort((a, b) => a.distance - b.distance))
            setChosedShelter(updatedShelters[0])
        } catch (error) {
            console.log(error)
        }
        setShelterLoading(false)
    }

    const getSheltersFirst = async () => {
        try {
            setShelterLoading(true)
            console.log('work')
            const res = await getShelters()
            save('SHELTERS', res.data)
            let shelters = [...res.data]
            // console.log(coords, 'COORDS')
            let updatedShelters
            if (get('COORD')) {
                updatedShelters = shelters.map(item => ({
                    ...item,
                    distance: calcCrow(item.latitude, item.longitude, coords[0], coords[1])
                }))
            } else {
                updatedShelters = shelters.map(item => ({
                    ...item,
                    distance: 0.00
                }))
            }

            setSheltersData(updatedShelters)
            setChosedShelter(res.data[0])
        } catch (error) {
            console.log(`Chosed shelters error - ${error}`)
        }
        setShelterLoading(false)
    }

    useEffect(() => {
        getSheltersFunc()
    }, [coords])

    useEffect(() => {
        const fetchData = async () => {

            try {

                // Получаем данные о событиях
                const selectedEvent = await getMyEvents();

                setMyEvents(selectedEvent.data)
            } catch (err) {
                console.error("Ошибка при получении данных:", err);
            }
        };

        fetchData(); // Вызов асинхронной функции для получения данных
    }, [coords]); // Хук будет срабатывать при изменении `coords`


    const setShelter = (item) => {
        // console.log('ITEM', item)
        setChosedShelter(item)
        setSelectedItem(item)
        setSelectedEvent(null)
    }

    useEffect(() => {
        if (chosedShelter && myEvents) {
            // Собираем все id событий из массива myEvents
            const allEventIdsToRemove = myEvents.flatMap(event => event.events.map(e => e.id));

            // Фильтруем события в chosedShelter.all_events, исключая те, чьи id есть в allEventIdsToRemove
            const updatedAllEvents = chosedShelter.all_events.filter(event => !allEventIdsToRemove.includes(event.id));

            // Обновляем chosedShelter с новым списком all_events
            setChosedShelter(prevShelter => ({
                ...prevShelter,
                all_events: updatedAllEvents
            }));
        }
    }, [myEvents, selectedItem]);



    const fetchController = async () => {
        if (!get('SHELTERS')) {
            await getSheltersFirst()
            // window.location.reload() убираем перезагрузку страницы в случае, если результат не получили
        } else {
            console.log('End')
            return null
        }
    }

    useEffect(() => {
        fetchController()
    }, [])

    async function fetchUserCounts(eventId) {
        try {
            const response = await axios.get(`https://dobrayaimperia.ru/api/events/${eventId}/user-count/`, {
                headers: {
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxMDM3NjgwMzY5NCwiaWF0IjoxNzM2ODkwMDk0LCJqdGkiOiJjYTcxYzA3ZmQ0ZDc0NjNiYjQ2ODVhNGY3ODc0MjVkZSIsInVzZXJfaWQiOjMxfQ.sph5To_lpJWuuy7Osz2janTBqWZeRZ1YxOsfyWDgs5c`
                }
            })

            console.log('API response:', response) // Логирование ответа от API

            console.log(response)

            return response.data
        } catch (error) {
            console.error('Error fetching data for event', eventId, error) // Логирование ошибки для каждого события
            return null // Возвращаем null в случае ошибки
        }
    }

    useEffect(() => {
        if (chosedShelter) {
            const userCountPromises = chosedShelter.all_events.map((event) => fetchUserCounts(event.id))

            Promise.all(userCountPromises)
                .then((userCounts) => {
                    // Логируем результат перед установкой состояния
                    console.log('User counts:', userCounts)

                    // Фильтруем только те данные, которые не равны null или undefined
                    const filteredUserCounts = userCounts.filter(count => count !== null && count !== undefined)

                    setSheltersTotalCount(filteredUserCounts)
                })
                .catch((error) => {
                    console.error('Error with Promise.all:', error)
                })
        }
    }, [chosedShelter])

    function handleShelterClick(shelter) {
        setChosedShelter(shelter)
    }

    function handleSlideChange(swiper) {
        const isBeginning = swiper.isBeginning
        const isEnd = swiper.isEnd
        const activeIndex = swiper.activeIndex

        if (activeIndex >= 1) {
            setIsMiddle(true)
        }

        setIsEnd(isEnd)
        setIsBeginning(isBeginning)
    }

    const sideblockContentCards = document.querySelector('.sheltersSwiper')

    useEffect(() => {

        if (!sideblockContentCards) {
            return
        }

        if (isBeginning) {
            sideblockContentCards.classList.remove('left')
            sideblockContentCards.classList.add('right')
        } else if (isEnd) {
            sideblockContentCards.classList.add('left')
            sideblockContentCards.classList.remove('right')
        }

        if (isMiddle && !isEnd && !isBeginning) {
            sideblockContentCards.classList.add('right')
            sideblockContentCards.classList.add('left')
        }
    }, [isEnd, isBeginning, isMiddle, sideblockContentCards])

    return (
        <div className='sideblock__content'>
            {isShelterLoading ? (
                <div className='sideblock__content-card-title'>Загрузка...</div>
            ) : (
                <>
                    {
                        sheltersData.length > 0 ?
                            <div className='sideblock__content-cards'>
                                {
                                    isMobileDevice() ? sheltersData.map((item) => (

                                        <SideblockCard
                                            key={item.id}
                                            onClick={() => handleShelterClick(item)}
                                            active={item.id === chosedShelter?.id}
                                            image={item.photos[0]?.image}
                                            name={item.name}
                                            distance={item?.distance.toFixed(2)}
                                        />

                                    )) : <Swiper
                                        className='sheltersSwiper'
                                        slidesPerView={2}
                                        spaceBetween={8}
                                        modules={[Navigation]}
                                        navigation
                                        onSlideChange={(swiper) => handleSlideChange(swiper)}
                                        onReachBeginning={(swiper) => handleSlideChange(swiper)}
                                        onReachEnd={(swiper) => handleSlideChange(swiper)}
                                    >
                                        {sheltersData.map((item) => (
                                            <SwiperSlide>
                                                <SideblockCard
                                                    key={item.id}
                                                    onClick={() => handleShelterClick(item)}
                                                    active={item.id === chosedShelter?.id}
                                                    image={item.photos[0]?.image}
                                                    name={item.name}
                                                    distance={item?.distance.toFixed(2)}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                }
                            </div>
                            : (
                                <span className='no__shelters__found__error'>
                                    <h2>Приюты не найдены</h2>
                                </span>
                            )
                    }
                </>
            )}

            {(chosedShelter && typeof chosedShelter === 'object' && chosedShelter.hasOwnProperty('description')) ?
                <div className='sideblock__content-wrapper'>
                    <div className='card sideblock__content-card sideblock__content-about'>
                        <div className='sideblock__content-card-title sideblock__content-about-title'>О ПРИЮТЕ</div>
                        <div className='sideblock__content-about-description'>
                            {chosedShelter.description}
                        </div>
                    </div>

                    {chosedShelter.photos.length > 0 ?
                        <div className='card sideblock__content-card'>
                            <div className='sideblock__content-card-title'>ФОТОГРАФИИ</div>
                            <div className='sideblock__content-albums'>
                                <Albums albums={chosedShelter.photos} />
                            </div>
                        </div> : null}

                    {chosedShelter.all_events.length > 0 ?
                        <div className='card sideblock__content-card sideblock__content-events'>
                            <div className='sideblock__content-card-title'>Ближайшие мероприятия</div>
                            <div className='sideblock__content-event-cards'>
                                <Events handleSlideChange={handleSlideChange} selectedEvent={selectedEvent} events={chosedShelter.all_events} onEventSelect={handleEventSelect} sheltersTotalCount={sheltersTotalCount} chosedShelter={chosedShelter} />
                            </div>
                        </div> : <h3 style={{ textAlign: 'center' }}>Новые мероприятия отсутствуют</h3>}
                </div>
                : ''}

            {selectedEvent ? <div onClick={() => handleButtonClick() } className='sideblock__content-btn primary-btn green-btn'>Принять участие</div>
                : null}
        </div>
    )
}
