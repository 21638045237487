// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    max-width: 1230px;
    margin: 0 auto;
    position: relative;
}

@media (max-width: 1440px) {
    .wrapper {
        max-width: 1000px;
        margin: 0 auto;
    }
}

@media (max-width: 1240px) {
    .wrapper {
        max-width: 900px;
        margin: 0 auto;
    }
}

@media (max-width: 992px) {
    .wrapper {
        max-width: 768px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .wrapper {
        max-width: 576px;
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .wrapper {
        max-width: 480px;
        margin: 0 auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/wrapper/wrapper.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;QACjB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ","sourcesContent":[".wrapper {\n    max-width: 1230px;\n    margin: 0 auto;\n    position: relative;\n}\n\n@media (max-width: 1440px) {\n    .wrapper {\n        max-width: 1000px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 1240px) {\n    .wrapper {\n        max-width: 900px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 992px) {\n    .wrapper {\n        max-width: 768px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 768px) {\n    .wrapper {\n        max-width: 576px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 576px) {\n    .wrapper {\n        max-width: 480px;\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
