import React, { useEffect, useState } from 'react'
import person from '../../../../assets/images/person.png'
import './events.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';

export const Events = ({ events, selectedEvent, onEventSelect, sheltersTotalCount, chosedShelter }) => {
    const [isBeginning, setIsBeginning] = useState(true)
    const [isMiddle, setIsMiddle] = useState(false)
    const [isEnd, setIsEnd] = useState(false)

    function handleSlideChange(swiper) {
        const isBeginning = swiper.isBeginning
        const isEnd = swiper.isEnd
        const activeIndex = swiper.activeIndex

        if (activeIndex >= 1) {
            setIsMiddle(true)
        }

        setIsEnd(isEnd)
        setIsBeginning(isBeginning)
    }

    const sideblockContentCards = document.querySelector('.swiper-events-horizontal')

    useEffect(() => {

        if (!sideblockContentCards) {
            return
        }

        if (isBeginning) {
            sideblockContentCards.classList.remove('left')
            sideblockContentCards.classList.add('right')
        } else if (isEnd) {
            sideblockContentCards.classList.add('left')
            sideblockContentCards.classList.remove('right')
        }

        if (isMiddle && !isEnd && !isBeginning) {
            sideblockContentCards.classList.add('right')
            sideblockContentCards.classList.add('left')
        }
    }, [isEnd, isBeginning, isMiddle, sideblockContentCards])

    function isMobileDevice() {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString)

        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${day}.${month} • ${hours}:${minutes}`
    }

    useEffect(() => {
        if (events.length > 0) {
            onEventSelect(events[0])
        }
    }, [events])

    if (isMobileDevice()) {
        return (
            <>
                {events.map((event, i) => {
                    const shelter = Array.isArray(sheltersTotalCount)
                        ? sheltersTotalCount.find((el) => el.id === event.id)
                        : null

                    return (
                        <div key={i} className={`event ${selectedEvent?.id === event.id ? 'event--selected' : ''}`} onClick={() => onEventSelect(event)}>
                            <div className='event__name'>{event.name}</div>
                            <img
                                src={event.photo}
                                className='event__image'
                                alt='image-event'
                            />
                            <div className='event__user__amount'>
                                {shelter ? shelter.user_count : '0'}
                                <img src={person} alt='Users' />
                            </div>
                            <div className='event__date'>
                                {formatDate(event.when)}
                            </div>
                        </div>
                    )
                })}
            </>
        )
    } else {
        return (
            <Swiper slidesPerView={3}
                className='swiper-events-horizontal'
                spaceBetween={13}
                modules={[Navigation]}
                navigation
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                onReachBeginning={(swiper) => handleSlideChange(swiper)}
                onReachEnd={(swiper) => handleSlideChange(swiper)}>
                {events.map((event, i) => {
                    const shelter = Array.isArray(sheltersTotalCount)
                        ? sheltersTotalCount.find((el) => el.id === event.id)
                        : null

                    return (
                        <SwiperSlide>
                            <div key={i} className={`event ${selectedEvent?.id === event.id ? 'event--selected' : ''}`} onClick={() => onEventSelect(event)}>
                                <div className='event__name'>{event.name}</div>
                                <img
                                    src={event.photo}
                                    className='event__image'
                                    alt='image-event'
                                />
                                <div className='event__user__amount'>
                                    {shelter ? shelter.user_count : '0'}
                                    <img src={person} alt='Users' />
                                </div>
                                <div className='event__date'>
                                    {formatDate(event.when)}
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }
}
