import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet, useParams } from "react-router-dom";
import { Home } from './screens/home/home';
import { Auth } from './screens/auth/auth';
import { Reg } from './screens/reg/reg';
import { NewPass } from './screens/newpass/newpass';
import LocalStorage from './utils/LocalStorage';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { ResetPass } from './screens/resetpass/resetpass';
import { PointProvider } from './context/ChosenItem';
import { Privacy } from './screens/privacy-policy/privacy';
import { Main } from './screens/main/main';
import { VerifyEmail } from './screens/verifyEmail/verifyEmail';

const ScrollToTops = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

const { get } = new LocalStorage;

export function ProtectedRoute({ children }) {
  let access = get('accessToken');
  let refresh = get('refreshToken');

  if (!access && !refresh) {
    return <Navigate to="/auth" replace />
  }
  return children
}

function AlreadyReg({ children }) {
  let access = get('accessToken');
  let refresh = get('refreshToken');
  let user = get('user');

  if (access && refresh && user) {
    return <Navigate to="/map" replace />
  }
  return children
}

function App() {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      if (!hasError) {
        setHasError(true);
        // Перенаправление в обычный браузер
        const url = "dobrayaimperia.ru/";
        const intentUrl = `intent://${url}#Intent;scheme=http;end`;

        if (message === "Script error." || message === "Script error") {
          window.location.href = intentUrl;
          window.close();
          return alert('Требуется открыть страницу в браузере')
        } else {
          console.error("Ошибка:", error);
        }
      }
    };
  }, [hasError]);

  return (
    <BrowserRouter>
      <ScrollToTops>
        <div className="App">
          <ReactNotifications />
          <Routes>
            <Route path='/' element={
              <AlreadyReg>
                <Main />
              </AlreadyReg>
            } />
            <Route path="/map" element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } />
            <Route path="/privacy-policy" element={<Privacy />} exact />
            <Route path="/auth" element={
              <AlreadyReg>
                <Auth />
              </AlreadyReg>
            } exact />
            <Route path="/reg" element={
              <AlreadyReg>
                <Reg />
              </AlreadyReg>
            } exact />
            <Route path="/newpass" element={
              <AlreadyReg>
                <NewPass />
              </AlreadyReg>
            } exact />
            <Route path="/resetpass" element={
              <AlreadyReg>
                <ResetPass />
              </AlreadyReg>
            } exact />
            <Route path="/api/password-reset/confirm/:uid/:token/" element={
              <AlreadyReg>
                <NewPass />
              </AlreadyReg>
            } exact />
            <Route path={`/email-verify/:key`} element={<VerifyEmail/>}/>
          </Routes>
        </div>
      </ScrollToTops>
    </BrowserRouter>
  );
}


export default App;
