import React, { useEffect, useState } from 'react'
import './verifyEmail.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import LocalStorage from '../../utils/LocalStorage'

export const VerifyEmail = () => {

    let [pageStatus, setPageStatus] = useState('Ожидание..')

    const params = useParams()
    const { save } = new LocalStorage;

    async function fetchToLoginAfterVerify() {
        try {
            const response = await axios.get(`https://dobrayaimperia.ru/api/accounts/registration/account-confirm-email/${params.key}`)
            save('accessToken', response.data.access);
            save('refreshToken', response.data.refresh);
            save('user', response.data);
            setPageStatus('Аккаунт успешно подтвержден')
            window.location.reload();
        } catch (error) {
            console.log(error)
            setPageStatus('Произошла ошибка.. Повторите позже')
            window.location.href = 'https://dobrayaimperia.ru'
        }
    }

    useEffect(() => {
        fetchToLoginAfterVerify()
    }, [])

    return (
        <div className='verify__email__container'>
            <h1>{pageStatus}</h1>
        </div>
    )
}