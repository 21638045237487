// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event {
    min-width: 122px;
    height: 147px;

    padding: 10px 5px 5px 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-radius: 15px;
    border: 1px solid #EFF1F7;
    background: #FFF;

    box-sizing: border-box;
    cursor: pointer;
}

.event--selected {
    border: 1px solid green;
}

.event__name {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.event__image {
    height: 70px;
}

.event__date {
    width: 100%;
    height: 29px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--primary-color);
    font-size: 12px;
    font-weight: 600;

    border-radius: 100px;
    background: #EFF1F7;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/home/events/events.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;;IAEb,yBAAyB;;IAEzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;;IAE9B,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;;IAEhB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;;IAEhB,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".event {\n    min-width: 122px;\n    height: 147px;\n\n    padding: 10px 5px 5px 5px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n\n    border-radius: 15px;\n    border: 1px solid #EFF1F7;\n    background: #FFF;\n\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.event--selected {\n    border: 1px solid green;\n}\n\n.event__name {\n    font-size: 14px;\n    font-weight: 600;\n    text-align: center;\n}\n\n.event__image {\n    height: 70px;\n}\n\n.event__date {\n    width: 100%;\n    height: 29px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    color: var(--primary-color);\n    font-size: 12px;\n    font-weight: 600;\n\n    border-radius: 100px;\n    background: #EFF1F7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
