import React from 'react'
import './asidePanel.css'
import getIcon from '../../../../assets/getIcon'

export const AsidePanel = ({ indexes, currIndex, asidePanelHandler, setCurrIndex, scrollToSection }) => {

  let [isAsideClosing, setAsideClosing] = React.useState(false)

  function asidePanelActiveStatusHandler() {
    setAsideClosing(!isAsideClosing)

    setTimeout(() => {
      asidePanelHandler()
    }, 350);
  }

  return (
    <div className={`aside__overlay ${isAsideClosing ? 'closing' : ''}`} onClick={asidePanelActiveStatusHandler}>
      <aside className='main__page__aside__panel' onClick={(e) => e.stopPropagation()}>
        <div className="aside__navigation__container">
          {indexes.map((index) => (
            <button
              className={index.id === currIndex.id ? 'active' : 'not-active'}
              key={index.id}
              type='button'
              onClick={() => {
                setCurrIndex(index);
                scrollToSection(index.sectionRef);
                asidePanelActiveStatusHandler()
              }}
            >
              {index.name}
            </button>
          ))}
        </div>
        <div className='close__button' onClick={asidePanelActiveStatusHandler}>
          <img src={getIcon('Close__bold.svg')} alt='Close'/>
        </div>
      </aside>
    </div>
  )
}